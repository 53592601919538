/* ==========================================================================
   #SIDEBAR
   ========================================================================== */

/** 
 * NOTE: The layout, width of the sidebar is controlled by `o-page__sidebar` in
 *       `o-page` object, found in `objects/_objects.page.scss`.
 */

.c-sidebar {
	position: relative;
	height: 100%;
	margin: 0;
	padding-bottom: $spacer;
	background-color: #222C3C;
	overflow: auto;
	z-index: $z-index-200; // DEV NOTE: COMPARE WITH `o-page__sidebar`
	@include scrollbar;
}

/**
 * 1. Height of sidebar brand should equal the height of the navbar for perfect 
 *    alignment.
 * 2. You can change the height of brand/logo image as you want, but its height
 *    shouldn't be more than navbar's height.
 */

.c-sidebar__brand {
	display: flex;
	align-items: center;
	height: $navbar-height; /* [1] */
	padding-left: $spacer;
	border-bottom: $base-border-width solid #2a3547;
	color: $grey-300;
	font-size: 16px;
	vertical-align: middle;

	&:hover {
		color: $grey-300;
		text-decoration: none;
	}
}

.c-sidebar__brand-img {
	display: inline-block;
	margin-right: $spacer-xsmall;
	background-color: #00A8FF;
	border-radius: 3px;
	padding: 9px;
	width: 50px;
	height: 50px;
}

.c-sidebar__title {
	margin-top: 25px;
	margin-bottom: 15px;
	padding-left: 30px;
	color: $color-mute;
	font-size: 12px;
	text-transform: uppercase;
}

.c-sidebar__divider {
	display: block;
	width: 100%;
	height: 1px;
	background-color: #2A3547;
}

/**
 * Sidebar naviagtion menu
 */

.c-sidebar__item {
	position: relative;
	transition: background-color 0.2s;

	/**
	 * 1. Add small arrow to `c-menu__link` when `c-menu__item` has sub-menu.
	 */
	&.has-submenu > .c-sidebar__link::after { /* [1] */
		display: inline-block;
		position: absolute;
		top: 12px;
		right: 30px;
		color: #B7C0CD;
		font-family: $base-icon-font;
		content: "\f0d7"; // fa-caret-down
	}

	&.is-open {
		background-color: #1D2531;
		box-shadow: inset 3px 0 0 0 #00A8FF;

		> .c-sidebar__link::after {
			content: "\f0d8"; // fa-caret-up
		}
	}
}

.c-sidebar__link {
	display: flex;
	align-content: center;
	align-items: center;
	padding: 10px 0 10px 30px;
	transition: background-color .2s;
	color: #B7C0CD;
	font-size: .875rem;

	&.is-active {
		background-color: #1D2531;
		box-shadow: inset 3px 0 0 0 #00A8FF;
	}

	&:hover {
		color: $white;
		text-decoration: none;
	}
}

/**
 * Sub Menu
 */
.c-sidebar__submenu {
	/**
	 * Bootstrap classes used to show and hide sidebar submenu.
	 * This behaviour depends on collapse.js plugin.
	 */
	&.collapse {
		display: none;
	}

	&.show {
		display: block;
	}

	&.collapsing {
		position: relative;
		height: 0;
		transition: height 0.3s;
		overflow: hidden;
	}
}

/* Style Variation - Light Sidebar
   ========================================================================== */

.c-sidebar--light {
	border-right: $base-border-width solid $base-border-color;
	background-color: $white;

	.c-sidebar__divider {
		background-color: $base-border-color;
	}

	.c-sidebar__brand {
		border-color: $base-border-color;
		color: $heading-color;
	}

	.c-sidebar__item {
		&.has-submenu > .c-sidebar__link::after { /* [1] */
			color: #B7C0CD;
		}

		&.is-open {
			background-color: #FAFBFC;
		}
	}

	.c-sidebar__link {
		color: $color-mute;

		&.is-active {
			background-color: transparent;
			color: $heading-color;

			i {
				color: $link-color;
			}

			&:hover {
				color: #2ea1f8;
			}
		}

		&:hover {
			color: $heading-color;
		}
	}

	/**
	 * 1. Add a slight shadow to the sidebar to make it popup a little bit
	 *    on small screens.
	 */
	@include respond-to($breakpoint-desktop) {
		box-shadow: 1px 1px 5px rgba(0,0,0,.1); /* [1] */
	}
}



/* Sidebar toggle
   ========================================================================== */

/**
 * An icon the is used to toggle (show/hide) sidebar on small screen, you will 
 * find this component placed in the main navbar.
 */

.c-sidebar-toggle {
	display: none;
	position: relative;
	width: $nav-toggle-width;
	height: $nav-toggle-height;
	margin: 0 $spacer-small 0 0; /* [1] */
	padding: 0; /* [1] */
	border: 0; /* [1] */
	outline: none;
	background-color: transparent; /* [1] */
	cursor: pointer;

	@include respond-to($breakpoint-desktop) {
		display: block; /* [2] */
	}
}

/**
 * The hamburger icon itself, 3 bars stacked on top of each other. 
 */

.c-sidebar-toggle__bar {
	display: block;
	position: relative;
	width: $nav-toggle-bar-width;
	height: $nav-toggle-bar-height;
	transition: all .3s;
	border-radius: $nav-toggle-bar-border-radius;
	background-color: $nav-toggle-bar-background-color;


	&:first-child {
		transform: translateY(-6px);
	}

	&:last-child {
		transform: translateY(6px);
	}
}
