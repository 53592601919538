#login-logo{
  width: 50px;
}

.form-success {
  display: none;

  &.success {
    display: flex;
  }
}